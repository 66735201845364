.video_container {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center; 
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.video_detail {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.video_detail ul {
    list-style-type: none;
}

.player {
    height: 34rem;
    background-color: #000;
    margin-top: 2rem;
    display: flex;

}
    
.row {
    max-width: 96rem;
    margin: 0 auto; }
    .row:not(:last-child) {
      margin-bottom: 6rem; }
    .row::after {
      content: "";
      display: table;
      clear: both; }
    .row [class^="col-"] {
      float: left; }
      .row [class^="col-"]:not(:nth-child(3n+0)) {
        margin-right: 6rem; }
    .row .col-1-of-2 {
      width: calc((100% - 6rem) / 2); }
    .row .col-1-of-3 {
      width: calc((100% - 2 * 6rem) / 3);
      margin-bottom: 3rem; }
    .row .col-2-of-3 {
      width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem); }
    .row .col-1-of-4 {
      width: calc((100% - 3 * 6rem) / 4); }
    .row .col-2-of-4 {
      width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
    .row .col-3-of-4 {
      width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem); }
  
  
  