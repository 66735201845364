.Mediacard {
    width: 300px;
    margin: 10px;
    box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
    text-align: center;
    background-color: #1E1E1E;
    border-radius: 10px;
    color: white;
    animation: grow;
}

.card-data {
    font-size: 80%;
}

.updates {
    box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
    width: 250px;
    height: 250px;
}